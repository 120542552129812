import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section, {SectionHeader} from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import SEO from "../components/SEO";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import AppointmentDiv from "../components/AppointmentDiv";
import UnorderedList from "../components/UnorderedList";
import ExternalLink from "../components/ExternalLink";
import InternalLink from "../components/InternalLink";

function PrivacyPolicyPage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Privacy`]}
                title="Privacy Policy"
                description="The online privacy policy for Agile Psychiatry PLLC"
            />

            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader>Privacy Policy</SectionHeader>
                        <h3 className="text-2xl text-center mb-2 italic">Last Updated 11/11/2020 </h3>
                        <TextParagraph className="mb-8">
                            Agile Psychiatry places special emphasis on patient privacy, not only in compliance with legal statutes,
                            but also with respect to the relationship between a patient and their doctor, an essential element of
                            mental health treatment.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            This policy references the Health Insurance Portability and Accountabilty Act&apos;s (&quot;HIPAA&quot;)
                            privacy policies. Further information can be found at the U.S. Department of Health & Human Services <ExternalLink to="https://www.hhs.gov/hipaa/index.html">HIPAA website</ExternalLink>.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader>Overview</SectionHeader>
                        <TextParagraph className="mb-8">
                            This Privacy Policy describes how your personal information is collected, used, and shared
                            when you visit https://www.agilepsychiatry.com (the “Site”).
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            Agile Psychiatry has entered into business agreements with other companies that may also collect
                            data in the course of your use of the Site in order for Agile Psychiatry to carry out
                            its described services. Please see their respective Privacy Policies to understand how your information
                            may be collected and used. Note that where <ExternalLink to="https://www.gsa.gov/reference/gsa-privacy-program/rules-and-policies-protecting-pii-privacy-act">Personally Identifiable Information (&quot;PII&quot;)</ExternalLink> is
                            relevant, a HIPAA compliant agreement has been signed.
                        </TextParagraph>
                        <UnorderedList className="text-xl">
                            <li><ExternalLink to="https://support.google.com/a/answer/3407054">Google LLC</ExternalLink> (HIPAA compliant agreement)</li>
                            <li><ExternalLink to="https://www.charmhealth.com/privacy-policy.html">Medical Mine Inc (operators of CharmHealth)</ExternalLink> (HIPAA compliant agreement)</li>
                            <li><ExternalLink to="https://usefathom.com/privacy">Conva Ventures Inc (operators of Fathom Analytics)</ExternalLink> (No PII collected)</li>
                        </UnorderedList>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader>
                            Personal Information We Collect
                        </SectionHeader>
                        <TextParagraph className="mb-8">
                            Agile Psychiatry does not automatically collect any personal information
                            from your device. Agile Psychiatry&apos;s hosting
                            provider Google LLC uses information from your device
                            in order to provide this website and may use information from your device to monitor
                            and improve their services.
                            {/*When you visit the Site, we automatically collect certain information about your device,*/}
                            {/*including information about your web browser, IP address, time zone, and some of the cookies*/}
                            {/*that are installed on your device. Additionally, as you browse the Site, we collect information*/}
                            {/*about the individual web pages or products that you view, what websites or search terms referred*/}
                            {/*you to the Site, and information about how you interact with the Site. We refer to this*/}
                            {/*automatically-collected information as “Device Information.”*/}
                        </TextParagraph>
                        {/*<TextParagraph className="mb-8">*/}
                        {/*We collect Device Information using the following technologies:*/}

                        {/*- “Log files” track actions occurring on the Site, and collect data including your IP address,*/}
                        {/*browser type, Internet service provider, referring/exit pages, and date/time stamps.*/}
                        {/*- “Web beacons,” “tags,” and “pixels” are electronic files used to record information about*/}
                        {/*how you browse the Site.*/}
                        {/*[[INSERT DESCRIPTIONS OF OTHER TYPES OF TRACKING TECHNOLOGIES USED]]*/}
                        {/*</TextParagraph>*/}

                        <TextParagraph className="mb-8">
                            If you elect to submit feedback to Agile Psychiatry, submit a consulting request, or submit
                            an appointment request, we collect your name, email address, and other personal information
                            that you submit via secure, HIPAA compliant websites operated by our technology providers
                            Google LLC and Medical Mine Inc. (operators of CharmHealth).
                        </TextParagraph>

                        <TextParagraph className="mb-8">
                            Agile Psychiatry PLLC makes use of Fathom Analytics&apos; services to collect aggregated information
                            about access to the Site. Fathom Analytics is a privacy-focused
                            analytics provider and, per their <ExternalLink to="https://usefathom.com/privacy">privacy policy</ExternalLink>,
                            they do not collect personally identifiable information from your device. We use their
                            services for security, monitoring aggregate usage, verification of functionality, and
                            improvement of the Site.
                        </TextParagraph>

                        <TextParagraph>
                            Please note that we do not alter our Site’s data collection and use practices when we see a
                            Do Not Track signal from your browser. Our web analytics provider, Fathom Analytics, is
                            configured to respect Do Not Track signals from your browser.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader>How Do We Use Your Personal Information?</SectionHeader>
                        <TextParagraph className="mb-8">
                            {/*When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.*/}
                            We use your personal information in order to provide you with the services that you request.
                            In the case of feedback forms, we use this information to review and improve our services and
                            to potentially contact you for further information. For consulting or speaking requests, we
                            use this information to contact you and discuss your interest in consulting or speaking engagements.
                        </TextParagraph>
                        <TextParagraph>
                            For appointment requests, we use the information you provide in order to evaluate whether
                            Agile Psychiatry can provide health care services to your benefit. If a formal engagement
                            is entered into, we will use the information you provide in the course of your care as outlined
                            in our Notice of Privacy Practices provided to you at registration.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader>Sharing Your Personal Information</SectionHeader>
                        <TextParagraph className="mb-8">
                            We share your information with the 3rd parties described above in order to carry out the services
                            offered by Agile Psychiatry. All Personally Identifiable Information is managed in accordance
                            with HIPAA guidelines.
                        </TextParagraph>

                        <TextParagraph>
                            With all possible respect given to HIPAA and patient privacy guided by medical ethics, we may
                            also share your Personal Information to comply with applicable laws and regulations, to respond
                            to a subpoena, search warrant or other lawful request for information we receive, or to otherwise
                            protect our rights.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader>Data Retention</SectionHeader>
                        <TextParagraph className="mb-8">
                            When you supply feedback or request consulting or speaking services, we will maintain your
                            information for our records unless and until you ask us to delete this information. If by chance
                            some Personally Identifiable Information is submitted via these services and is relevant to
                            an active patient, we will maintain that information in accordance with the policies
                            outlined in our HIPAA Privacy Practices.
                        </TextParagraph>

                        <TextParagraph>
                            For appointment requests and Personally Identifiable Information, we will maintain
                            records in accordance with our Notice of Privacy Practices.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader>Minors</SectionHeader>
                        <TextParagraph>
                            The Site is not intended for individuals under the age of 16.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader>Changes</SectionHeader>
                        <TextParagraph>
                            We may update this privacy policy from time to time in order to reflect, for example,
                            changes to our practices or for other operational, legal or regulatory reasons.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader>Contact Us</SectionHeader>
                        <TextParagraph>
                            For more information about our privacy practices, if you have questions, or if you would
                            like to make a complaint, please <InternalLink to="/contact">contact us</InternalLink>.
                        </TextParagraph>
                </TextDiv>
            </SectionRow>
        </Section>
    <SVGCurve direction="right-slope" transition="mid-dark"/>
    <Section shade="dark">
        <SectionRow>
            <AppointmentDiv/>
        </SectionRow>
    </Section>
    {/*<SVGCurve direction="right-slope" color="text-ap-dark-bg" bgColor="bg-gray-200"/>*/}
</LayoutGeneral>
);
}


export default PrivacyPolicyPage;
